import React from "react"
import { injectIntl } from "gatsby-plugin-react-intl";

const TextBlock = ({ intl, content }) => (
    <div className={`text-block`}>
        {content.title &&
        <div className={`title`}>{content.title}</div>
        }
        {content.subtitle &&
        <div className={`subtitle`}>{content.subtitle}</div>
        }
        {content.text &&
        <div className={`text`} dangerouslySetInnerHTML={{__html: content.text}}></div>
        }
    </div>
)

export default injectIntl(TextBlock)
