import React from 'react';
import {injectIntl} from "gatsby-plugin-react-intl";
import quotes from "../../images/quotes.svg"

const Quote = ({ intl, content }) => (

    <div className={`quote`}>
        <div className={``}><div className={`quotes`}><img src={quotes} alt={``} /></div></div>
        <div className={`testimonial`}>
            <div className={`divider`}></div>
            <div className={`summary`}>{content.summary}</div>
            <div className={`text`} dangerouslySetInnerHTML={{ __html: content.text }}></div>
            <div className={`author`}>{content.author}</div>
            <div className={`role`}>{content.role}</div>
        </div>
    </div>

)

export default injectIntl(Quote)
